import React from 'react';
import { getIn } from 'seamless-immutable';

const ZionSkin = ({ publication }) => {
  const content = getIn(publication, ['content']);

  if (!content) {
    return null;
  }

  return <style dangerouslySetInnerHTML={{ __html: content }} />;
};

export default ZionSkin;
